import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const OptimizedImage = ({ 
  src,
  alt,
  className = '',
  wrapperClassName = '',
  width,
  height,
  effect = 'blur',
  placeholderSrc
}) => {
  // Funktion zum Generieren des srcSet für responsive Bilder
  const generateSrcSet = (url) => {
    if (!url) return '';
    
    // Für Contentful-Bilder
    if (url.includes('images.ctfassets.net')) {
      return `
        ${url}?w=300&fm=webp 300w,
        ${url}?w=600&fm=webp 600w,
        ${url}?w=900&fm=webp 900w,
        ${url}?w=1200&fm=webp 1200w
      `;
    }
    
    return '';
  };

  // Funktion zum Generieren der Größen basierend auf Bildschirmbreite
  const sizes = `
    (max-width: 576px) 100vw,
    (max-width: 768px) 600px,
    (max-width: 992px) 900px,
    1200px
  `;

  return (
    <LazyLoadImage
      src={src}
      alt={alt}
      className={className}
      wrapperClassName={wrapperClassName}
      effect={effect}
      width={width}
      height={height}
      placeholderSrc={placeholderSrc}
      srcSet={generateSrcSet(src)}
      sizes={sizes}
      loading="lazy"
      decoding="async"
      style={{ 
        display: 'block',
        maxWidth: '100%',
        height: 'auto'
      }}
    />
  );
};

export default OptimizedImage;
